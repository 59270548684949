<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col md="12">
            <b-form-group label="Title" invalid-feedback="Title is required.">
              <b-form-input
                placeholder="Enter title"
                ref="title"
                v-model.trim="myObj.homework.title"
                @focusout="CheckTitle()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Class"
              invalid-feedback="Class is required."
              ref="class"
            >
              <v-select
                ref="class"
                v-model="myObj.homework.cID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="classesOptions"
                :reduce="(val) => val.id"
                :clearable="false"
                label="name"
                @input="FillSections()"
                placeholder="Select class"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Section"
              invalid-feedback="Section is required."
              ref="section"
            >
              <v-select
                ref="section"
                v-model="myObj.homework.section"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="currentSections"
                :reduce="(val) => val.id"
                :clearable="false"
                label="section"
                placeholder="Select sections"
              />
              <!-- <v-select
                multiple
                ref="section"
                v-model="secIDs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="currentSections"
                :reduce="(val) => val.id"
                :clearable="false"
                label="section"
                @input="setSections()"
                placeholder="Select sections"
              /> -->
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Subject"
              invalid-feedback="Subject is required."
              ref="sub"
            >
              <v-select
                ref="sub"
                v-model="myObj.homework.subID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="subjects"
                :reduce="(val) => val.id"
                :clearable="false"
                label="subject"
                placeholder="Select subject"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Details"
              invalid-feedback="Details is required."
              ref="detail"
            >
              <b-form-textarea
                ref="detail"
                placeholder="Enter Details"
                v-model="myObj.homework.details"
                @focusout="checkDetails()"
                rows="3"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="date"
            >
              <flat-pickr
                :config="config"
                v-model="myObj.homework.date"
                class="form-control"
                placeholder="Select date."
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              label=""
              invalid-feedback="Documents are required"
              required
              ref="docs"
            >
              <b-button
                @click="$refs.mediaDoc.click()"
                variant="primary"
                :disabled="request || mydocloading || publishing"
                block
              >
                <b-spinner
                  v-if="mydocloading"
                  type="grow"
                  small
                  label="Loading..."
                />
                <span v-else>Upload Attachments</span>
              </b-button>
              <input
                type="file"
                id="mediaDoc"
                hidden
                ref="mediaDoc"
                multiple
                accept="image/*,video/*,application/pdf"
                @change="addMedia()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="d-flex flex-wrap">
            <div
              class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-50 mb-1"
              style="width: 80px; height: 80px; position: relative"
              v-for="(file, index) in attachments"
              :key="index"
              @click="openFile(file.url)"
            >
              <b-avatar
                size="20"
                variant="primary"
                style="position: absolute; top: -5px; right: -5px"
                button
                @click.stop="removeMedia(index)"
              >
                <feather-icon icon="XIcon" />
              </b-avatar>
              <feather-icon
                :icon="getFileIcon(file.type)"
                size="30"
                class="text-primary"
              />
            </div>
          </b-col>

          <!-- <b-col cols="12">
            <div>
              <b-badge
                style="margin-bottom: 5px"
                class="p-1"
                v-for="(file, index) in attachments"
                :key="index"
                variant="light-primary"
              >
                <a :href="file.url" target="_blank" class="text-primary">
                  {{ index + 1 + " : " + file.url.slice(0, 40) }}
                </a>

                <i
                  class="text-danger fa fa-trash"
                  style="cursor: pointer"
                  @click="removeMedia(index)"
                ></i>
              </b-badge>
            </div>
          </b-col> -->

          <b-col md="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="Add(false)"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
          <b-col md="12" class="mt-1" v-if="!isPublished">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="info"
              @click="Add(true)"
              :disabled="request || publishing"
              block
            >
              <b-spinner v-if="publishing" small type="grow" />
              <span v-else>Save & Publish</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <!-- <b-row>
          <b-col md="7">
            <b-form-group label="Title " invalid-feedback="Title is required.">
              <b-form-input
                placeholder="Enter tile."
                ref="title"
                v-model="myObj.title"
                @focusout="CheckTitle()"
              />
            </b-form-group>
  
            <b-form-group
              label="Message"
              invalid-feedback="Messgae is required."
              ref="msg"
            >
              <b-form-textarea
                ref="msg"
                placeholder="Enter message."
                v-model="myObj.message"
                @focusout="CheckMessage()"
                rows="5"
              ></b-form-textarea>
            </b-form-group>
  
            <div class="text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                :disabled="request"
                @click="Add()"
              >
                <b-spinner v-if="request" small type="grow" />
                <span v-if="request == false"> Save </span>
              </b-button>
            </div>
          </b-col>
          <b-col md="5">
            <b-row>
              <b-col md="12">
                <b-input-group class="input-group-merge w-100">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" class="text-muted" />
                  </b-input-group-prepend>
                  <b-form-input v-model="searchQuery" placeholder="Search..." />
                </b-input-group>
              </b-col>
  
              <b-col
                md="12"
                class="mt-1"
                style="display: flex; cursor: context-menu"
                v-for="item in items"
                :key="item.id"
                @click="Edit(item.id)"
              >
                <b-avatar class="mb-1" variant="light-primary" size="35">
                  <span style="font-size: 20px">{{
                    item.title.substring(0, 1)
                  }}</span>
                </b-avatar>
                <div class="" style="margin-left: 5px">
                  <h4 class="font-weight-bolder mb-0" style="margin-top: 5px">
                    {{ item.title.substring(1) }}
                  </h4>
                  <p style="max-height: 1.5em; overflow: hidden">
                    {{ item.message }}
                  </p>
                </div>
              </b-col>
          
            </b-row>
          </b-col>
        </b-row> -->

      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="6" cols="12" v-if="rights.add">
          <b-button
            class="mb-1 mb-xl-0"
            @click="AddOpen()"
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Homework</span>
          </b-button>
        </b-col>
        <b-col xl="2" lg="3" md="4" sm="6" cols="12">
          <b-form-group
            label=""
            invalid-feedback="Class is required."
            ref="fil_class"
            class="mb-1 mb-md-0"
          >
            <v-select
              ref="fil_class"
              v-model="filterObj.clsID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="[{ name: 'All Classes', id: 0 }, ...classesOptions]"
              :reduce="(val) => val.id"
              :clearable="false"
              label="name"
              @input="loadSections()"
              placeholder="Select class"
            />
          </b-form-group>
        </b-col>
        <b-col xl="2" lg="3" md="4" sm="6" cols="12">
          <b-form-group
            label=""
            invalid-feedback="Section is required."
            ref="fil_sec"
            class="mb-1 mb-md-0"
          >
            <v-select
              ref="fil_sec"
              v-model="filterObj.secID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="[{ section: 'All Sections', id: 0 }, ...filterSections]"
              :reduce="(val) => val.id"
              :clearable="false"
              label="section"
              placeholder="Select sections"
            />
          </b-form-group>
        </b-col>

        <b-col xl="2" lg="3" md="4" sm="6" cols="12">
          <b-form-group
            label=""
            invalid-feedback="Date is required."
            ref="fil_date"
            class="mb-1 mb-md-0"
          >
            <flat-pickr
              ref="fil_date"
              v-model="filterObj.date"
              :config="config"
              class="form-control"
              placeholder="Select Date"
            />
          </b-form-group>
        </b-col>
        <b-col xl="2" lg="3" md="4" sm="6" cols="12">
          <b-button
            class="mb-1 mb-lg-0"
            @click="LoadData()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <span class="align-middle">Show</span>
          </b-button>
          <!-- <b-button
            class="btn-icon mb-1 mb-lg-0 ml-1"
            variant="outline-primary"
            v-b-tooltip.hover.top
            title="Messages"
            @click="openMessage()"
          >
            <feather-icon icon="MailIcon" />
          </b-button> -->
        </b-col>

        <!-- mt-lg-0 mt-md-0 mt-sm-1  -->
        <b-col class="mt-1" xl="12" lg="12" md="12" sm="12" cols="12">
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :busy="dataLoading"
        show-empty
        responsive
        hover
        :selectable="rights.edit"
        select-mode="single"
        @row-selected="Edit($event[0])"
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #head(actions)="data">
          <div class="mr-5 text-right">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(title)="data">
          {{ data.item.data.title }}
        </template>
        <template #cell(details)="data">
          {{ data.item.data.details }}
        </template>

        <template #cell(date)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.item.data.date).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
          <br />
          <b-badge
            v-if="data.item.data.isPublished"
            variant="light-success"
            class="mt-50"
          >
            Published
          </b-badge>
        </template>

        <template #cell(class)="data">
          <b-badge variant="light-primary">
            <!-- {{ getClassName(data.item.cID) }} -->
            {{ data.item.data.clsName }}
          </b-badge>
        </template>
        <template #cell(section)="data">
          <b-badge variant="light-primary">
            <!-- {{ getSecName(data.item.cID, data.item.section) }} -->
            {{ data.item.data.section }}
          </b-badge>
        </template>
        <template #cell(subject)="data">
          <b-badge variant="light-primary">
            <!-- {{ getSubName(data.item.subID) }} -->
            {{ data.item.data.subject }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="text-center">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Edit"
              class="btn-icon mr-50"
              @click="Edit(data.item)"
              :disabled="editLoading"
            >
              <b-spinner
                v-if="editLoading && currentID == data.item.data.id"
                variant="light"
                small
              ></b-spinner>
              <feather-icon v-else icon="EditIcon" class="" />
            </b-button>
            <b-button
              v-if="rights.delete"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.right
              title="Delete"
              class="btn-icon"
              @click="Delete(data.item.data.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BAvatar,
    BFormTextarea,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    flatPickr,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BImg,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    // if (!this.$store.state.rights[this.$route.name]) {
    //   // console.log(this.$store.state.rights[this.$route.name]);
    //   this.$router.replace({
    //     name: "misc-not-authorized",
    //   });
    // } else {
    //   this.rights = this.$store.state.rights[this.$route.name];
    //   this.loadInfo();
    //   this.LoadData();
    // }
    this.rights = {
      view: true,
      add: true,
      edit: true,
      delete: true,
    };
    this.loadInfo();
    this.LoadData();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.data.title.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.data.details.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      rights: {},
      titleText: "This is title",
      myDepartments: [],
      request: false,
      publishing: false,
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Template",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,
      mydocloading: false,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "title", key: "title" },
        { label: "date", key: "date" },
        { label: "class", key: "class" },
        { label: "section", key: "section" },
        { label: "subject", key: "subject" },
        { label: "details", key: "details" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      rangeDate: null,
      attachments: [],
      myObj: {
        editIDs: [],
        homework: {
          id: 0,
          subID: 0,
          cID: 0,
          title: "",
          section: 0,
          details: "",
          subject: "",
          date: "",
          savedBy: 0,
          campusID: this.$store.state.userData.cId,
          savedOn: "",
          attachments: [
            {
              id: 0,
              hwID: 0,
              name: "",
              type: "",
              url: "",
              fileSize: 0,
            },
          ],
        },
        homeworkAttachment: [
          {
            id: 0,
            hwID: 0,
            name: "",
            type: "",
            url: "",
            fileSize: 0,
          },
        ],
        sections: [],
        db: this.$store.state.userData.db,
      },

      sendOptions: [
        { text: "None", value: "" },
        { text: "Inquiry", value: "Inquiry" },
        { text: "Student Admission", value: "Student Admission" },
        { text: "Fee Collection", value: "Fee Collection" },
        { text: "Defaulters", value: "Defaulters" },
        { text: " Student Attendance (P)", value: " Student Attendance (P)" },
        { text: " Student Attendance (A)", value: " Student Attendance (A)" },
        { text: " Staff Attendance (P)", value: " Staff Attendance (P)" },
        { text: " Staff Attendance (A)", value: " affnt Attendance (A)" },
      ],
      detailObj: {},
      timeout: 10000,
      mediaLoaded: false,
      docLoading: false,
      classes: [],
      classesOptions: [],
      currentSections: [],
      secIDs: [],
      subjects: [],

      filterObj: {
        clsID: 0,
        secID: 0,
        date: new Date().toJSON().split("T")[0], // "2023-01-17"
      },
      filterSections: [],
      editLoading: false,
      currentID: 0,
      isPublished: false,
    };
  },
  validations: {
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    getClassName(id) {
      let obj = this.classesOptions.find((el) => el.id == id);
      return obj ? obj.name : "-";
    },
    getSubName(id) {
      let obj = this.subjects.find((el) => el.id == id);
      return obj ? obj.subject : "-";
    },
    getSecName(clsID, id) {
      let obj = this.classes.find((el) => el.cls.id == clsID);
      if (obj) {
        let sec = obj.sections.find((el) => el.id == id);
        return sec ? sec.section : "-";
      } else return "-";
    },

    openMessage() {
      console.log("msg");
    },

    loadSections() {
      this.filterObj.secID = 0;
      if (this.filterObj.clsID == 0) {
        this.filterSections = [];
      } else {
        this.filterSections = this.classes.find(
          (el) => el.cls.id == this.filterObj.clsID
        ).sections;
      }
      // console.log(this.filterSections);
    },

    async Edit(item) {
      if (!item) return;
      // console.log("item", item);

      this.editLoading = true;
      this.currentID = item.data.id;

      this.attachments = [];
      this.myObj = {
        editIDs: [],
        homework: {},
        homeworkAttachment: [],
        sections: [],
        db: this.$store.state.userData.db,
      };

      var obj = {
        url:
          this.$store.state.domain +
          "Homework/GetSelected?id=" +
          item.data.id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj.homework = await this.get(obj);
      this.isPublished = this.myObj.homework.isPublished;
      this.editLoading = false;
      this.myObj.editIDs = [item.data.id];
      this.attachments = [...item.attachments];
      this.myObj.homeworkAttachment = [...item.attachments];
      console.log(this.myObj);

      this.visibility = true;
      this.FillSections();
      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["class"];
      elem.state = undefined;
      var elem = this.$refs["sub"];
      elem.state = undefined;
      var elem = this.$refs["section"];
      elem.state = undefined;
      var elem = this.$refs["date"];
      elem.state = undefined;

      this.sidebarTitle = "Edit Homework";
      this.sidebarButton = "Update";
    },

    AddOpen() {
      this.myObj = {
        editIDs: [],
        homework: {
          id: 0,
          subID: 0,
          cID: 0,
          title: "",
          section: 0,
          details: "",
          subject: "",
          date: new Date().toJSON(),
          savedBy: 0, // for admin
          campusID: this.$store.state.userData.cId,
          savedOn: new Date().toJSON(),
          attachments: [],
        },
        homeworkAttachment: [],
        sections: [],
        db: this.$store.state.userData.db,
      };
      this.attachments = [];
      this.isPublished = false;
      this.visibility = true;
      var elem = this.$refs["title"];
      elem.state = undefined;
      var elem = this.$refs["class"];
      elem.state = undefined;
      var elem = this.$refs["sub"];
      elem.state = undefined;
      var elem = this.$refs["section"];
      elem.state = undefined;

      this.sidebarTitle = "Add Homework";
      this.sidebarButton = "Save";
    },

    CheckTitle() {
      var elem = this.$refs["title"];
      if (this.myObj.homework.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDetails() {
      var elem = this.$refs["detail"];
      if (this.myObj.homework.details.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckClass() {
      var elem = this.$refs["class"];
      if (this.myObj.homework.cID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSubject() {
      var elem = this.$refs["sub"];
      if (this.myObj.homework.subID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSection() {
      var elem = this.$refs["section"];
      //   if (this.secIDs.length == 0) {
      if (this.myObj.homework.section == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      var elem = this.$refs["date"];
      if (!this.myObj.homework.date) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckMessage() {
      var elem = this.$refs["msg"];
      if (this.myObj.message == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    isDoc(url) {
      return /\.(pdf)$/.test(url);
    },
    removeMedia(index) {
      this.attachments.splice(index, 1);
      this.myObj.homeworkAttachment = this.attachments;
      // console.log(this.myObj.homeworkAttachment);
    },
    addMedia() {
      let media = this.$refs.mediaDoc.files;
      if (media.length > 0) {
        this.mydocloading = true;
        let formData = new FormData();
        media.forEach((el) => {
          formData.append("file", el);
        });
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.attachments.push({
                id: 0,
                hwID: 0,
                name: this.getType(el.path),
                type: this.getType(el.path),
                url: el.path,
                fileSize: el.size,
              });
            });

            this.myObj.homeworkAttachment = this.attachments;
            this.mydocloading = false;
            // console.log(this.myObj.homeworkAttachment);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.mydocloading = false;
          });
      }
    },
    getType(url) {
      if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/i.test(url)) return "Image";
      else if (/\.(mp4|amv|webm)$/i.test(url)) return "Video";
      else if (/\.(pdf|doc|ppt|docx|xls|css|txt|xlsx)$/i.test(url))
        return "pdf";
      else return "Link";
    },
    openFile(url) {
      window.open(url, "_blank");
    },
    getFileIcon(type) {
      if (type) {
        if (type.toLowerCase() === "image") return "ImageIcon";
        else if (type.toLowerCase() === "video") return "VideoIcon";
        else if (type.toLowerCase() === "pdf") return "FileTextIcon";
        else return "LinkIcon";
      }
      return "LinkIcon";
    },

    hideSideBar() {
      this.visibility = false;
    },
    FillSections() {
      this.currentSections = [];
      this.secIDs = [];
      this.classes.forEach((elem) => {
        if (elem.cls.id === this.myObj.homework.cID) {
          if (elem.sections.length != 0) {
            this.currentSections = elem.sections;
            // this.currentSections.unshift({ section: "All Sections", id: 0 });
          } else {
            // console.log("null");
            this.currentSections = [];
          }
        }
      });
      // console.log("This", this.currentSections);
    },
    setSections() {
      if (this.secIDs[this.secIDs.length - 1] == 0) {
        this.secIDs = this.secIDs.filter((e) => e == 0);
        this.myObj.sections = [];
        this.currentSections.forEach((el) => {
          if (el.id !== 0) this.myObj.sections.push(el.id);
        });
      } else {
        this.secIDs = this.secIDs.filter((e) => e !== 0);
        this.myObj.sections = this.secIDs;
      }
      // console.log(this.secIDs);
      // console.log(this.myObj.sections);
    },
    async loadInfo() {
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      // console.log("class", this.classes);
      this.classesOptions = this.classes.map((el) => el.cls);

      var obj2 = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.subjects = await this.get(obj2);
      // console.log("sub", this.subjects);
    },

    async LoadData() {
      this.dataLoading = true;
      // GetByClass
      var obj = {
        url: this.$store.state.domain + "Homework/LoadDataWeb?type=homework",
        body: {
          db: this.$store.state.userData.db,
          cID: this.$store.state.userData.cId,
          clsID: this.filterObj.clsID,
          sections: this.filterObj.secID == 0 ? [] : [this.filterObj.secID],
          tID: 0,
          date: this.filterObj.date,
        },
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      };
      let res = await this.post(obj);
      // console.log("res", res);
      if (Array.isArray(res)) {
        this.items = res;
      } else {
        this.items = [];
      }

      this.dataLoading = false;

      // console.log("hw", this.items);
    },

    async Add(val) {
      this.CheckTitle();
      this.CheckClass();
      this.CheckSubject();
      this.CheckSection();
      this.CheckDate();
      this.checkDetails();
      if (
        this.CheckSection() == false ||
        this.CheckClass() == false ||
        this.CheckSubject() == false ||
        this.checkDetails() == false ||
        this.CheckTitle() == false ||
        this.CheckDate() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (val) {
          this.publishing = true;
          this.myObj.homework.isPublished = true;
        } else {
          this.request = true;
        }
        // console.log("obj:", this.myObj);
        this.myObj.sections = [this.myObj.homework.section];
        var status = await this.post({
          url: this.$store.state.domain + "Homework/Save",
          body: this.myObj,
          message: "Homework added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        this.publishing = false;
        if (status) {
          this.LoadData();
          this.visibility = false;
        }
        // if (this.myObj.homework.id == 0) {
        // }
        // else {
        //   var status = await this.put({
        //     url:
        //       this.$store.state.domain +
        //       "Homework/" +
        //       this.myObj.homework.id +
        //       "?db=" +
        //       this.$store.state.userData.db,
        //     body: this.myObj.homework,
        //     message: "Homework updated successfully.",
        //     context: this,
        //     token: this.$store.state.userData.token,
        //   });
        //   this.request = false;
        //   if (status) {
        //     this.LoadData();
        //     this.visibility = false;
        //   }
        // }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Homework/DeleteHomework?db=" +
            this.$store.state.userData.db +
            "&id=" +
            id,
          body: null,
          message: "Homework removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.border-dashed {
  border: 1px solid var(--primary);
  border-style: dashed;
}
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.file-type {
  background-color: #e62e2d;
  font-family: "Helvetica", sans-serif;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  height: 80px;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
</style>
